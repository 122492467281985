<template>
  <div class="guide-cnt11">
    <h6 class="h6">售后政策</h6>
    <div class="cnt">
      <p class="p">1、关于质量 ：微微定生产的印品中，您若发现质量问题，请及时与客服联系，核实问题原因，判定问题程度，确认责任人，办理退款、补印或重印；</p>
      <p class="p">2、关于运输 ：如果在签收时印品有损坏或其他不正常现象，请及时联系网站客服人员协商处理；</p>
      <p class="p">3、关于数量： 由于微微定所有印品均属定制型，制作过程复杂，难免会出现个别数量误差，若因数量不足，将由微微定退还相应差价；</p>
      <p class="p">4、关于发票： 微微定提供统一正规机打发票，若需要发票请在下单时认真填写发票公司全称、地址、电话、税号。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt11',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt11 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:30px;
    }
  }
}
</style>
