<!-- 购物指南-售后政策 -->
<template>
  <div class="guidesale">
  	<crumbs class='crumbs'  :location='location' @exportCls='exportCls' ></crumbs>
  	<div class="box">
  		<guidelt :type='11'></guidelt>
      <guidecnt11></guidecnt11>
  	</div>
  </div>
</template>
<script>
import crumbs from '@/components/crumbs/crumbs.vue';//面包屑导航
import guidelt from '@/components/guide-lt/guide-lt.vue';//帮助中心-lt
import guidecnt11 from '@/components/guide-cnt11/guide-cnt11.vue';//帮助中心-售后政策
export default {
  name: 'guidesale',
  metaInfo() {
  	return {
  		title:this.htmltitle,
    meta: [
      {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
    ]
  	}
  },
  data(){
  	return {
  	  htmltitle:'购物指南-微微定',
  	  location:[{Name:'首页'},{Name:'购物指南',Id:1}],//面包屑
  	}
  },
  components: {
  	crumbs,
  	guidelt,
    guidecnt11
  },
  methods:{
    exportCls(v){
      if(!v.Id){
        this._router({then:()=>{
          this.$router.push({path: "/index.html"})
        }})
      }
    },
  },
  mounted(){
  	this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
  	})
  }
}
</script>
<style lang="scss" scoped>
.guidesale {
	width: 1200px;margin:0 auto;position: relative;padding-bottom:20px;
	.box {
		zoom:1;margin-top:10px;
		&:after {
			content: "";display: block;clear: both;
		}
	}
}
</style>
